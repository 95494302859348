import React from "react"
import { graphql, Link } from 'gatsby'
import { StaticImage, GatsbyImage } from "gatsby-plugin-image"
import {Helmet} from "react-helmet";

import { cleanSlug, makeMoney, getBackLink } from '../utilities.js'
import Layout from "../components/_layout";

export default function Details({ data, pageContext }) {
  const pageTitle = data.details.frontmatter.company+' '+data.details.frontmatter.name;
  return <Layout>
    <Helmet>
      <title>{data.details.frontmatter.company+' '+data.details.frontmatter.name}</title>
      <body className={cleanSlug(data.details.frontmatter.company)} />
    </Helmet>
    <div className="container">
      <div className="row flex-md-row flex-column-reverse">
        <h1 className="col-xl-10 col-md-9 col-12 mt-2 mb-3">{ pageTitle }</h1>
        <Link className="col-xl-2 col-md-3 col-12 mt-md-none mt-3 text-end align-self-center" id="back-link" to={getBackLink()}>
          <span className="btn btn-dark btn-sm btn-special special-back-to-listing">
            <i class="bi bi-arrow-bar-left"></i>&nbsp; Back to Listing
          </span>
        </Link>
      </div>

      <div className="row flex-md-row flex-column-reverse">
        <div className="col-xl-7 col-lg-7 col-md-6 col-12 d-flex pt-3 flex-column intro-text">
          <p dangerouslySetInnerHTML={{__html: data.details.html}}></p>
          <p className="links">
            <a className="btn btn-dark btn-special special-wikipedia-link" href={data.details.frontmatter.wikipediaLink}>
              Would you like to know more? &nbsp;<i className="bi bi-link-45deg"></i>
            </a>
          </p>
        </div>
        {(()=>{
          if( data.details.fields.imageSharp === null ){
            return <StaticImage className="col-xl-5 col-lg-5 col-md-6 col-12" objectFit="contain" src={'../../data/images/_no_console.png'} alt={'No image, try rebuilding.'} />
          }else{
            return <GatsbyImage className="col-xl-5 col-lg-5 col-md-6 col-12" objectFit="contain" image={data.details.fields.imageSharp.gatsbyImageData} alt={data.details.frontmatter.name} />
          }
        })()}
      </div>

      <table className="table col-12">
        <caption><h3>Releases</h3></caption>
        {data.details.fields.adjustedReleases.map((release)=>{
          const releaseDescription = ""+release.releaseDescription !== "" ? release.releaseDescription : "Initial Release";
          return <tbody>
            <ReleaseDateHeader release={release} />
            <tr className="release-description-row"><td colspan="6"><div className="bg-light px-2 py-1">{releaseDescription}</div></td></tr>
            <tr className="price-record-headers">
              <th>Date</th>
              <th>Price</th>
              <th>Inflation</th>
              <th>Today</th>
              <th>Difference</th>
              <th>Source</th>
            </tr>
            {release.priceHistory.map((pricePoint)=>{
              return <PricePointRow pricePoint={pricePoint} backupSource={data.details.frontmatter.wikipediaLink} />
            })}
          </tbody>
        })}
      </table>

      {/* <AdjustmentOverLifetime monthlyAdjustments={data.details.fields.monthlyAdjustedPrices}/> */}
      {/* <pre>{ JSON.stringify( data, null, 2 ) }</pre> */}

    </div>
  </Layout>
}

let lastReleaseDate = '';
const ReleaseDateHeader = ({ release }) => {
  if( lastReleaseDate !== release.priceHistory[0].date ){
    lastReleaseDate = release.priceHistory[0].date;
    return <tr className="release-date-header"><td colspan="5"><h5 className="col-12 align-self-center">{lastReleaseDate}</h5></td></tr>
  }
  return null;
}

const PricePointRow = ({pricePoint, backupSource}) => {
  const source = pricePoint.source === null ? backupSource : pricePoint.source
  const sourceName = source.replace(/^.+\/\/.*?([^.]+\.[^/.]+)\/.*/,'$1');  
  return <tr className="price-record">
    <td>
      { pricePoint.shortDate }
    </td>
    <td>
      { makeMoney(pricePoint.price) }
    </td>
    <td>
      {(pricePoint.adjustedPrice.percent).toFixed(2)}%
    </td>
    <td>
      {pricePoint.adjustedPrice.money}
    </td>
    <td>
      {pricePoint.adjustedPrice.moneyDiff}
    </td>
    <td>
      <a href={source} target="_blank" rel="noreferrer">{sourceName}</a>
    </td>
  </tr>
}

// Useful entryID for testing, PS1: 894b6d9a-82c1-5d54-8256-fc9cd9a7b24e
export const query = graphql`
  query DetailsPageQuery($entryId: String = "") {
    details: markdownRemark(id: {eq: $entryId}) {
      id
      frontmatter {
        name
        company
        image
        type
        wikipediaLink
      }
      html
      fields {
        imageSharp {
          gatsbyImageData
        }
        adjustedReleases {
          discontinuedDate
          discontinuedSource
          priceHistory {
            shortDate: date(formatString: "l")
            date(formatString: "dddd, MMMM Do, YYYY")
            relativeDate: date(fromNow: true)
            price
            adjustedPrice {
              money
              moneyDiff
              percent
            }
            source
          }
          releaseDescription
          source
        }
      }
    }
  }
`;
